.m-0 {
    margin: 0;
}
.p-0 {
    padding: 0;
}
.mt5 {
    padding-top: 5px;
}
.mt10 {
    padding-top: 10px;
}
.mt15 {
    padding-top: 15px;
}
.mt20 {
    padding-top: 20px;
}
.mb5 {
    padding-bottom: 5px;
}
.mb10 {
    padding-bottom: 10px;
}
.mb15 {
    padding-bottom: 15px;
}
.mb20 {
    padding-bottom: 20px;
}
.ml5 {
    padding-left: 5px;
}
.ml10 {
    padding-left: 10px;
}
.ml15 {
    padding-left: 15px;
}
.ml20 {
    padding-left: 20px;
}
.mr5 {
    padding-right: 5px;
}
.mr10 {
    padding-right: 10px;
}
.mr15 {
    padding-right: 15px;
}
.mr20 {
    padding-right: 20px;
}

.pt5 {
    padding-top: 5px;
}
.pt10 {
    padding-top: 10px;
}
.pt15 {
    padding-top: 15px;
}
.pt20 {
    padding-top: 20px;
}
.pb5 {
    padding-bottom: 5px;
}
.pb10 {
    padding-bottom: 10px;
}
.pb15 {
    padding-bottom: 15px;
}
.pb20 {
    padding-bottom: 20px;
}
.pl5 {
    padding-left: 5px;
}
.pl10 {
    padding-left: 10px;
}
.pl15 {
    padding-left: 15px;
}
.pl20 {
    padding-left: 20px;
}
.pr5 {
    padding-right: 5px;
}
.pr10 {
    padding-right: 10px;
}
.pr15 {
    padding-right: 15px;
}
.pr20 {
    padding-right: 20px;
}
