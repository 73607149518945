html,
body {
    height: 100%;
    margin: 0;
    font-family: 'Arial', 'Ubuntu', sans-serif;
}

@media print {
  html, body {
    height: 99%;
  }
}

.main-container {
    position: absolute;
    top: 64px;
    left: 0px;
    right: 0;
    bottom: 0;
    padding: 20px;
}

.mb-20 {
    margin-bottom: 20px;
}

a {
  color: #1d3b51;
}

mat-card {
  width: calc(100% - 70px);
  height: calc(100% - 20px);
}

.mat-list-item-small {
  font-size: 14px !important;
  height: 28px !important;
}

.mat-list-icon-small {
  font-size: 16px !important;
  height: 16px !important;
}

.mat-tab-body.mat-tab-body-active {
  padding-bottom: 20px !important;
}
