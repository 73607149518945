@media screen and (max-width: 992px) {
    .main-container {
        left: 0px;
    }
}

@media screen/* and (max-width: 2500px)*/ {
    .push-right {
        #sidebar {
            left: 0 !important;
        }
    }
}
